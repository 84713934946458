
import fnApi from '/utils/common/methods'

export default {
    name: 'Policy',
    components: {},
    async fetch() {
        // 获取协议文本
        const response = await this.$api.help.getPolicy({
            data: JSON.stringify({
                id: this.$route.query.id || '16793696198020vLSBIcB'
            })
        });
        const res = response?.result || {};
        this.seo = res
        // this.info = res
        // if(this.info.content) {
        //     this.info.content = this.info.content.replaceAll('rem', 'px')
        // }
    },
    props: {
        policyId: [Number, String]
    },
    data() {
        return {
            hasApp: false,
            query: {
                id: ''
            },
            app: {
                headers: {}
            },
            seo: {},
            info: {},
            headCompo: {
                hasBack: true
            },
            countryInfo: {}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        // this.setChangeSelect()
    },
    methods: {
        // 获取协议
        getPolicyAjax(config) {
            this.$api.help.getPolicy({
                data: JSON.stringify({
                    id: this.$route.query.id || this.policyId || '16793696198020vLSBIcB'
                })
            }, config).then(response => {
                const res = response.result || {};
                this.info = res
                if(this.info.content) {
                    this.info.content = this.info.content.replaceAll('rem', 'px')
                }
                this.$emit('get-data', this.info)
                this.$fnApi.debounce(() => {
                    this.setChangeSelect()
                }, 100)
            });
        },
        // 协议国家选择
        setChangeSelect() {
            let debounce = 0;
            let clear = setInterval(() => {
                let tag = document.querySelector('select');
                tag && (tag.value = this.countryInfo.englishName)
                tag && tag.addEventListener('change', (e) => {
                    const index = tag.selectedIndex;
                    const tagOp = tag.options[index];
                    const obj = {
                        id: tagOp.getAttribute('data-id'),
                        countryCode: tagOp.getAttribute('data-countryCode'),
                        englishName: tagOp.getAttribute('data-englishName'),
                    }
                    this.countryInfo = obj
                    // 缓存设置
                    this.getPolicyAjax({
                        countryCode: this.countryInfo.countryCode
                    })
                    // this.$storage.set('country', obj, true)
                    this.$storage.remove('order/placeAddress')
                    // this.$router.go(0)
                });
                debounce += 1;
                if(tag || debounce > 10) {
                    tag && clearInterval(clear)
                }
            }, 1000)
        },
        // 获取APP参数
        getAppConfig() {
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    if(e) {
                        this.app.headers = e
                    }
                    delete this.app.headers.loginTerminal
                    delete this.app.headers.currency
                    this.getPolicyAjax(this.app.headers)
                })
            } else {
                this.getPolicyAjax()
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.seo.title,
                keywords: this.seo.title,
                description: this.seo.title,
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.countryInfo = this.$storage.get('country') || {}
            this.hasApp = fnApi.bridgeApp.getApp().isApp
            if(this.query.hideBack) {
                this.headCompo.hasBack = false
            }
            this.getAppConfig()
        }
    },
}
