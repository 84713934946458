
export default {
    name: 'AboutUs',
    layout: 'main-head',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            tab: {
                id: 2,
                list: []
            },
            media: {
                controls: false
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        changeTab() {
            const point = `aboutUs-item-point${this.tab.id}`;
            this.$VueScrollTo.scrollTo(`.${point}`, '', {
                offset: -123,
                duration: 0
            })
        },
        scrollTab(e) {
            let topArr = [];
            for(let i = 1; i < 6; i++) {
                const el = document.querySelector(`.aboutUs-item-point${i}`);
                el && topArr.push(el.getBoundingClientRect().top)
            }
            topArr.forEach((val, i) => {
                if(val < 354) {
                    this.tab.id = i + 1
                }
            })
        },
        handleVideo() {
            const ref = this.$refs.video;
            ref && ref.play()
            this.media.controls = true
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('About Us | YFN Jewelry'),
                keywords: this.$translate('About Us | YFN Jewelry'),
                description: this.$translate('YFN mission is to offer our customers across the world a universe of contemporary jewelry personalized based on their feelings, thereby expressing their individuality.'),
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.tab.list = [{
                title: this.$translate('Development Path'),
                name: 1
            }, {
                title: this.$translate('Exquisite Craftsmanship'),
                name: 2
            }, {
                title: this.$translate('Professional Service Team'),
                name: 3
            }, {
                title: this.$translate('Our Brand Vision'),
                name: 4
            }, {
                title: this.$translate('Online International Sites'),
                name: 5
            }]
        }
    },
}
