
export default {
    name: 'HelpCenter',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            search: {
                val: ''
            },
            collapse: {
                val: []
            },
            cellCompo: {
                hasRightIcon: true,
                list: [],
                cell: {
                    'title-class': 'c-ellipsis'
                }
            },
            collapseCompo: {
                list: [],
                hasRightIcon: true,
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getHelpAjax()
    },
    methods: {
        // 获取帮助列表
        getHelpAjax() {
            this.$api.help.getHelpList().then(response => {
                const res = response.result || {};
                this.cellCompo.list = res.hot || []
                this.collapseCompo.list = res.questionClassificationList || []
                this.skeleton = false
            })
        },
        handleQuestion(obj) {
            this.$router.push({
                name: 'HelpArticle',
                params: {
                    id: obj.id
                }
            })
        },
        handleOther(obj) {
            this.$router.push({
                name: 'HelpArticle',
                params: {
                    id: obj.id
                }
            })
        },
        handleSearch() {
            this.$router.push({
                name: 'HelpSearch',
                query: {
                    keyword: this.search.val
                }
            })
        }
    },
}
