
export default {
    name: 'HelpArticle',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            query: {},
            info: {},
            hasApp: false,
            config: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getHeader()
    },
    methods: {
        getHelpDetailAjax() {
            this.$api.help.getHelpDetail({
                id: this.query.id,
                fb: ''
            }, this.config).then(response => {
                // helpful -> 0 没帮助 1 有帮助、clickStatus -> 0 未点赞 1 已点赞
                const res = response.result || {};
                this.info = res
                this.skeleton = false
            })
        },
        setHelpStatusAjax() {
            this.$api.help.setHelpStatus({
                problemId: this.query.id,
                helpful: this.query.helpful
            }, this.config).then(response => {
                const res = response.result || {};
                this.getHelpDetailAjax()
            })
        },
        getHeader() {
            this.hasApp = this.$fnApi.bridgeApp.getApp().isApp
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    e && (this.config = e)
                    this.hasLogin = this.config['Mall-User-Token']
                    delete this.config.loginTerminal;
                    delete this.config.currency
                    this.getHelpDetailAjax()
                })
            } else {
                this.getHelpDetailAjax()
            }
        },
        handleAgree(val) {
            if(!this.hasLogin) {
                if(this.hasApp) {
                    this.$fnApi.bridgeApp.bridge({
                        name: 'jumpLogin',
                    })
                } else {
                    this.$router.push({
                        name: 'Login'
                    })
                }
                return
            }
            if(this.info.clickStatus) {
                return
            }
            this.query.helpful = val
            this.setHelpStatusAjax()
        },
        handleEChat() {
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'openEchat'
                })
                return
            } else {
                ECHAT && ECHAT.customMiniChat()
            }
            // 客服
        },
        // 初始化
        initialSet() {
            this.query = {
                ...this.$route.query,
                ...this.$route.params
            }
            this.hasLogin = this.$login()
        }
    },
}
