
export default {
    name: 'Paypal',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
            src: ''
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Paypal'),
                keywords: this.$translate('Paypal'),
                description: this.$translate('Paypal'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        initialSet() {
            this.query = this.$route.query
            this.src = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=${this.query.cmd}&token=${this.query.token}`
        }
    },
}
