
export default {
    name: 'Feedback',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            hasApp: false, // 是否app内
            status: false,
            user: {}, // 用户信息
            options: { // 筛选
                list: []
            },
            tab: {
                id: 0
            },
            config: {},
            rules: { // 表单校验规则
                hasCheck: false,
            },
            form: { // 表单
                pics: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.setHeader()
    },
    methods: {
        // 获取用户信息
        getUserAjax() {
            this.$api.user.getUseInfo({
                id: this.user.id
            }, this.config).then(response => {
                const res = response.result || {};
                this.form.email = res.email
            })
        },
        // 获取问题列表
        getHelpListAjax() {
            this.$api.help.getHelpType({}, this.config).then(response => {
                const res = response.result || {};
                this.options.list = []
                res.forEach(item => {
                    this.options.list.push({
                        text: item.name,
                        id: item.code
                    })
                })
            })
        },
        // 反馈接口
        feedbackAjax() {
            this.$api.help.setFeedBack({
                ...this.form,
                pics: JSON.stringify(this.form.pics)
            }, this.config).then(response => {
                const res = response;
                if(res.code == 'success') {
                    this.status = true
                }
            }).catch(err => {})
        },
        // 删除上传图片
        handleDeleteImg(obj) {
            this.form.pics.splice(obj.i, 1)
            // this.$Dialog.confirm({
            //     message: this.$translate('Are you sure to delete'),
            //     confirmButtonText: this.$translate('Yes'),
            //     confirmButtonColor: '#333',
            //     cancelButtonText: this.$translate('Cancel'),
            //     cancelButtonColor: '#999'
            // }).then(() => {
            //     this.form.pics.splice(obj.i, 1)
            // }).catch(() => {})
        },
        // 上传
        beforeRead(e) {
            if(this.form.pics.length > 4) {
                this.$Toast(this.$translate('Upload a maximum of 5 photos'))
                return false
            }
            return true
        },
        afterRead(e) {
            this.form.pics.push(e.oss.reUrl)
        },
        // 提交
        submitFrom() {
            this.rules.hasCheck = true
        },
        failedForm() {
            this.rules.hasCheck = false
        },
        handleSubmit() {
            this.$refs['form'].submit()
            this.$fnApi.debounce(() => {
                this.rules.hasCheck && this.feedbackAjax()
            }, 10)
        },
        // 跳转
        jumpHome() {
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'jumpMine'
                })
            } else {
                this.$router.push({
                    name: 'Mine'
                })
            }
        },
        // 获取app参数
        getHeaders() { // 获取APP设备信息
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    e && (this.config = e)
                    delete this.config.loginTerminal;
                    delete this.config.currency
                    this.initialSet()
                    this.hasLogin && this.getUserAjax()
                    this.getHelpListAjax()
                })
            } else {
                this.initialSet()
                this.hasLogin && this.getUserAjax()
                this.getHelpListAjax()
            }
        },
        // 是否在 app 内
        setHeader() {
            this.$nextTick(() => {
                this.hasApp = this.$fnApi.bridgeApp.getApp().isApp
                this.getHeaders()
            })
        },
        // 初始化
        initialSet() {
            this.rules.type = [{
                required: true,
            }]
            this.rules.content = [{
                required: true,
                message: this.$translate('At least 10 characters'),
                validator: (val) => {
                    return val.length >= 10
                }
            }]
            this.hasLogin = !this.hasApp ? this.$login() : this.config['Mall-User-Token']
            if(!this.hasLogin) {
                if(this.hasApp) {
                    this.$fnApi.bridgeApp.bridge({
                        name: 'jumpLogin'
                    }).then(e => {})
                } else {
                    this.$router.push({
                        name: 'Login'
                    })
                }
            } else {
                if(this.hasApp) {
                    this.user.id = this.config.userId
                } else {
                    this.user.id = this.$storage.get('userId')
                }
            }
        }
    },
}
