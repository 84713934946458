
export default {
    name: 'HelpSearch',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
            search: {
                val: '',
                num: '',
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getSearchAjax()
    },
    methods: {
        getSearchAjax() {
            this.$api.help.getHelpSearch({
                keyword: this.search.val,
                pageNo: this.page.pageNum,
                pageSize: this.page.pageSize
            }).then(response => {
                const res = response.result || {};
                this.search.list = res.list || []
                this.search.num = res.totalCount
            })
        },
        handleSearch() {
            this.page.pageNum = 1
            this.getSearchAjax()
        },
        handleOther(obj) {
            this.$router.push({
                name: 'HelpArticle',
                params: {
                    id: obj.id
                }
            })
        },
        initialSet() {
            this.query = this.$route.query
            this.search.val = this.query.keyword || ''
        }
    },
}
